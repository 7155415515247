<template>
	<div class="hidden lg:flex lg:gap-8 lg:h-20 z-20">
		<button @click="navigateTo('/')" class="bg-[#1E1E1E]/80 px-14 flex items-center justify-center rounded-[80px]">
			<img src="/images/logo.svg" alt="logo" />
		</button>
		<div class="bg-[#1E1E1E]/80 w-full flex items-center justify-between pr-2 pl-16 rounded-[80px]">
			<div class="flex items-center justify-center text-white font-bold space-x-6">
				<NuxtLink
					:to="item.href ?? '#'"
					@click="item.action"
					v-for="item in dynamicItems as typeof items"
					class="cursor-pointer hover:text-[#CEF26F] transition-all duration-200"
				>
					{{ item.label }}
				</NuxtLink>
			</div>
			<slot name="button" v-if="$slots.button"></slot>
			<Button @click="() => navigateTo('/login')" class="w-fit px-14" v-else title="Entrar"> Entrar </Button>
		</div>
	</div>
</template>

<script setup lang="ts">
const props = defineProps<{
	items?: {
		label: string
		href?: string
		action: () => void
	}[]
}>()

const dynamicItems = computed(() => {
	return (
		props.items || [
			{
				label: 'Loja',
				href: '/streamers',
			},
			{
				label: 'Como funciona',
				href: '/',
			},
		]
	)
})
</script>
